import React, { useContext } from "react";
import { Link } from "gatsby";
import Skeleton from "react-loading-skeleton";
import { DataContext } from "../../context/DataContext";
import { AuthContext } from "../../context/AuthContext";

import imageNotFound from "../../assets/image/image-not-found.jpg";

const Sidebar = (props) => {
  const { talent } = useContext(DataContext);
  const { auth } = useContext(AuthContext);

  return (
    <>
      <div {...props}>
        <div className="pl-lg-5">
          <div className="bg-white shadow-9 rounded-4">
            <div className="px-5 py-11 text-center border-bottom border-mercury">
              {props.loading ? (
                <Skeleton width={54} height={54} circle />
              ) : (
                <Link
                  to="#"
                  onClick={(e) => e.preventDefault()}
                  className="mb-4"
                >
                  {talent?.imageUrl ? (
                    <img
                      className="circle-104"
                      src={talent.imageUrl}
                      alt={`${talent?.firstname} ${talent?.lastname} Devshop profile`}
                    />
                  ) : (
                    <img
                      src={imageNotFound}
                      alt="Image not found"
                      className="circle-104"
                      width={104}
                    />
                  )}
                </Link>
              )}
              <h4 className="mb-0">
                <Link
                  to="#"
                  onClick={(e) => e.preventDefault()}
                  className="text-black-2 font-size-6 font-weight-semibold"
                >
                  {props.loading ? (
                    <Skeleton width={200} height={34} />
                  ) : (
                    `${talent?.firstname} ${talent?.lastname}`
                  )}
                </Link>
              </h4>
              <p className="mb-8">
                <Link
                  to="#"
                  onClick={(e) => e.preventDefault()}
                  className="text-gray font-size-4"
                >
                  {props.loading ? (
                    <Skeleton width={200} height={26} />
                  ) : (
                    talent?.title
                  )}
                </Link>
              </p>

              <div className="mb-2">
                {props.loading ? (
                  <Skeleton width={200} height={26} />
                ) : (
                  <p className="font-size-4 font-weight-semibold mb-0">
                    <a
                      href={talent?.github}
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray text-break hover-color-primary"
                    >
                      <span className="mr-4">Github Profile</span>
                      <i className="fa fa-external-link-alt"></i>
                    </a>
                  </p>
                )}
              </div>
              {auth && (auth?.client || auth?.admin) ? (
                <div className="d-flex align-items-center justify-content-center flex-wrap mt-9">
                  {props.loading ? (
                    <Skeleton width={250} height={40} />
                  ) : (
                    <Link
                      to={`/hire-talent/${talent.account_id}`}
                      state={{ id: talent.account_id }}
                      className="btn btn-green text-uppercase btn-small rounded-3 focus-reset"
                    >
                      Hire Me
                    </Link>
                  )}
                </div>
              ) : null}
              <div className="d-flex align-items-center justify-content-center flex-wrap mt-6">
                {props.loading ? (
                  <Skeleton width={250} height={26} />
                ) : (
                  <p className="text-gray font-size-4 font-weight-semibold">
                    {talent.currency === "USDT"
                      ? `${talent?.rate} USDT/ hour`
                      : `${talent.rate} ${talent.currency} (${Math.floor(
                          (talent.rate * props.exchangeRate).toFixed(2)
                        )} USDT /hour)`}
                  </p>
                )}
              </div>
              {auth?.talent || !auth ? (
                <div className="d-flex align-items-center justify-content-center flex-wrap mt-4">
                  {props.loading ? (
                    <Skeleton width={250} height={40} />
                  ) : (
                    <span className="btn btn-green text-uppercase btn-small px-8 rounded-3 focus-reset">
                      {`Login to hire ${talent?.firstname}`}
                    </span>
                  )}
                </div>
              ) : null}
            </div>

            <div className="px-9 pt-lg-5 pt-9 pt-xl-9 pb-5">
              {/* <h5 className="text-black-2 mb-8 font-size-5">Contact Info</h5>
              
              <div className="mb-7">
                <p className="font-size-4 mb-0">Location</p>
                <h5 className="font-size-4 font-weight-semibold mb-0 text-black-2 text-break">
                  {props.loading ? (
                    <Skeleton width={200} height={26} />
                  ) : `${talent?.city}, ${talent?.country}`}
                </h5>
              </div>
              
              <div className="mb-7">
                <p className="font-size-4 mb-0">E-mail</p>
                {props.loading ? (
                  <Skeleton width={200} height={26} />
                ) : (
                  <h5 className="font-size-4 font-weight-semibold mb-0">
                    <a
                      className="text-black-2 text-break"
                      href="mailto:name_ac@gmail.com"
                    >
                      {talent?.email}
                    </a>
                  </h5>
                )}
              </div>
              
              <div className="mb-7">
                <p className="font-size-4 mb-0">Phone</p>
                {props.loading ? (
                  <Skeleton width={200} height={26} />
                ) : (<h5 className="font-size-4 font-weight-semibold mb-0">
                  <a className="text-black-2 text-break" href="tel:+999565562">
                    {talent?.phone}
                  </a>
                </h5>
                )}
              </div> */}
              {props.loading ? (
                <Skeleton width={200} height={26} />
              ) : (
                <div className="icon-link d-flex align-items-center justify-content-center flex-wrap mb-7">
                  <a
                    href={talent?.github}
                    target="_blank"
                    rel="noreferrer"
                    className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                  >
                    <i className="fab fa-github"></i>
                  </a>
                  {talent?.linkedin ? (
                    <a
                      href={talent?.linkedin || "/#"}
                      target="_blank"
                      rel="noreferrer"
                      className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  ) : null}
                  {talent?.website ? (
                    <a
                      href={talent?.website}
                      target="_blank"
                      rel="noreferrer"
                      className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                    >
                      <i className="fas fa-globe-africa"></i>
                    </a>
                  ) : null}
                  {talent?.dribble ? (
                    <a
                      href={talent?.dribble}
                      target="_blank"
                      rel="noreferrer"
                      className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                    >
                      <i className="fab fa-dribbble"></i>
                    </a>
                  ) : null}
                  {talent?.behance ? (
                    <a
                      href={talent?.behance}
                      target="_blank"
                      rel="noreferrer"
                      className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                    >
                      <i className="fab fa-behance"></i>
                    </a>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
