import React, { useContext, useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import sanitizeHtml from "sanitize-html";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../../components/PageWrapper";
import ProfileSidebar from "../../components/ProfileSidebar";
import { DataContext } from "../../context/DataContext";
import { AuthContext } from "../../context/AuthContext";
import { getExchangeRate } from "../../services/tokenConverter";

import imgB3 from "../../assets/image/svg/harvard.svg";
import imageNotFound from "../../assets/image/image-not-found.jpg";

const CandidateProfile = ({ location, params }) => {
  const [loading, setLoading] = useState(true);
  const [exchangeRate, setExchangeRate] = useState(0);
  const { auth } = useContext(AuthContext);
  const { talent, experts, getTalentDetails } = useContext(DataContext);
  const param = params[`*`];
  const [selectedId, setSelectedId] = useState();

  const changeSelectedId = (id) => {
    if (selectedId == id) {
      setSelectedId(-1);
    } else {
      setSelectedId(id);
    }
  };

  const fetchExchangeRate = async () => {
    const rate = await getExchangeRate();
    setExchangeRate(rate);
  };

  const fetchTalentDetails = async (talentId) => {
    if (talentId) {
      await fetchExchangeRate();
      await getTalentDetails(talentId);
    } else {
      navigate(-1);
    }
    setLoading(false);
  };

  const createMarkup = (html) => {
    return {
      __html: sanitizeHtml(html, {
        allowedAttributes: false,
        allowedTags: [
          "address",
          "article",
          "aside",
          "footer",
          "header",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "hgroup",
          "main",
          "nav",
          "section",
          "blockquote",
          "dd",
          "div",
          "dl",
          "dt",
          "figcaption",
          "figure",
          "hr",
          "li",
          "main",
          "ol",
          "p",
          "pre",
          "ul",
          "a",
          "abbr",
          "b",
          "bdi",
          "bdo",
          "br",
          "cite",
          "code",
          "data",
          "dfn",
          "em",
          "i",
          "kbd",
          "mark",
          "q",
          "rb",
          "rp",
          "rt",
          "rtc",
          "ruby",
          "s",
          "samp",
          "small",
          "span",
          "strong",
          "sub",
          "sup",
          "time",
          "u",
          "var",
          "wbr",
          "caption",
          "col",
          "colgroup",
          "table",
          "tbody",
          "td",
          "tfoot",
          "th",
          "thead",
          "tr",
        ],
      }),
    };
  };

  useEffect(() => {
    const talentId = param;
    fetchTalentDetails(talentId);
    return () => setLoading(true);
  }, []);

  return (
    <>
      <PageWrapper headerConfig={auth ? { button: "profile" } : null}>
        <div className="bg-default-2 pt-22 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            {/* <!-- back Button --> */}
            <div className="row justify-content-center">
              <div className="col-12 dark-mode-texts">
                <div className="mb-9">
                  <Link
                    to="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                    className="d-flex align-items-center ml-4"
                  >
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-xxl-3 col-lg-4 col-md-5 mb-11 mb-lg-0">
                <ProfileSidebar loading={loading} exchangeRate={exchangeRate} />
              </div>

              <div className="col-12 col-xxl-9 col-lg-8 col-md-7 order-2 order-xl-1">
                <Tab.Container id="left-tabs-example" defaultActiveKey="one">
                  <div className="bg-white rounded-4 shadow-9 p-4">
                    <Nav
                      className="nav border-mercury pl-12 pt-6"
                      role="tablist"
                    >
                      <li className="tab-menu-items nav-item pr-12">
                        <Nav.Link
                          eventKey="one"
                          className="text-uppercase font-size-3 font-weight-bold text-default-color py-3 px-0"
                        >
                          Overview
                        </Nav.Link>
                      </li>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="one">
                        <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-5">
                          <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                            Bio
                          </h4>
                          {loading ? (
                            <div>
                              <p>
                                <Skeleton
                                  height={24}
                                  containerClassName="w-100"
                                />
                                <Skeleton
                                  height={24}
                                  containerClassName="w-100"
                                />
                                <Skeleton
                                  height={24}
                                  containerClassName="w-100"
                                />
                                <Skeleton
                                  height={24}
                                  containerClassName="w-100"
                                />
                              </p>
                            </div>
                          ) : (
                            <p
                              className="font-size-4 mb-8"
                              dangerouslySetInnerHTML={createMarkup(
                                talent?.bio
                              )}
                            />
                          )}
                        </div>
                        <div className="border-top pr-xl-0 pr-xxl-14 p-5 pl-xs-12 pt-7 pb-5">
                          <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                            Skills
                          </h4>
                          {loading ? (
                            <div className="d-flex">
                              <Skeleton
                                height={32}
                                width={100}
                                containerClassName="mr-2"
                              />
                              <Skeleton
                                height={32}
                                width={100}
                                containerClassName="mr-2"
                              />
                              <Skeleton
                                height={32}
                                width={100}
                                containerClassName="mr-2"
                              />
                            </div>
                          ) : (
                            <ul className="list-unstyled d-flex align-items-center flex-wrap">
                              {talent?.skills &&
                                talent.skills.map((skill, index) =>
                                  skill == "Other" ? null : (
                                    <li key={index}>
                                      <Link
                                        to="/#"
                                        onClick={(e) => e.preventDefault()}
                                        className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center"
                                      >
                                        {skill}
                                      </Link>
                                    </li>
                                  )
                                )}
                              {talent?.otherSkills
                                ? talent?.otherSkills
                                    .split(",")
                                    .map((skill, index) => (
                                      <li key={index}>
                                        <Link
                                          to="/#"
                                          onClick={(e) => e.preventDefault()}
                                          className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center"
                                        >
                                          {skill.trim()}
                                        </Link>
                                      </li>
                                    ))
                                : null}
                            </ul>
                          )}
                        </div>
                        <div className="border-top p-5 pl-xs-12 pt-7 pb-5">
                          <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                            Work Experience
                          </h4>
                          <div className="w-100">
                            <div className="d-flex align-items-center mb-9 flex-wrap flex-sm-nowrap">
                              <div className="w-100 mt-n2">
                                {loading ? (
                                  <div>
                                    <Skeleton height={24} width={200} />
                                  </div>
                                ) : (
                                  <div className="pb-6">
                                    {talent?.workExperience ? (
                                      talent?.workExperience.map(
                                        (work, index) => (
                                          <div
                                            className="accordion pb-8 pb-sm-2"
                                            id="accordionExample"
                                            key={index}
                                          >
                                            <div
                                              className="btn font-size-4 font-weight-bold"
                                              type="button"
                                              onClick={() =>
                                                changeSelectedId(index)
                                              }
                                            >
                                              {work.position} - {work.company}
                                            </div>
                                            <div
                                              key={index}
                                              id="collapseOne"
                                              className={` py-0 pl-2 collapse ${
                                                index == selectedId
                                                  ? "show"
                                                  : ""
                                              }  `}
                                            >
                                              <div
                                                className="card-body pt-0 border-left border-mercury"
                                                key={index}
                                              >
                                                <div className="d-flex flex-column flex-sm-row justify-content-between mt-2 mb-4">
                                                  <div>
                                                    <h3 className="font-size-4 font-weight-normal text-gray">
                                                      {work?.experience || ""}
                                                    </h3>
                                                  </div>
                                                  <div>
                                                    <h3 className="font-size-4 font-weight-normal text-gray">
                                                      {`${
                                                        work?.startDate
                                                          ? new Date(
                                                              work.startDate
                                                                .seconds *
                                                                1000 +
                                                                work.startDate
                                                                  .nanoseconds /
                                                                  1000000
                                                            ).toLocaleString(
                                                              "en-US",
                                                              {
                                                                month: "long",
                                                              }
                                                            )
                                                          : null
                                                      }-${
                                                        work?.startDate
                                                          ? new Date(
                                                              work.startDate
                                                                .seconds *
                                                                1000 +
                                                                work.startDate
                                                                  .nanoseconds /
                                                                  1000000
                                                            ).getFullYear()
                                                          : null
                                                      } to ${
                                                        work?.endDate
                                                          ? new Date(
                                                              work.endDate
                                                                .seconds *
                                                                1000 +
                                                                work.endDate
                                                                  .nanoseconds /
                                                                  1000000
                                                            ).toLocaleString(
                                                              "en-US",
                                                              {
                                                                month: "long",
                                                              }
                                                            )
                                                          : null
                                                      }-${
                                                        work?.endDate
                                                          ? new Date(
                                                              work.endDate
                                                                .seconds *
                                                                1000 +
                                                                work.endDate
                                                                  .nanoseconds /
                                                                  1000000
                                                            ).getFullYear()
                                                          : null
                                                      }`}
                                                    </h3>
                                                  </div>
                                                </div>
                                                <div
                                                  className="rich-text-editor-wrapper"
                                                  dangerouslySetInnerHTML={createMarkup(
                                                    work.description
                                                  )}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0 pl-2">
                                        No work experience added.
                                      </h3>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border-top p-5 pl-xs-12 pt-7 pb-5">
                          <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                            Education
                          </h4>
                          <div className="w-100">
                            <div className="d-flex align-items-center pr-11 mb-9 flex-wrap flex-sm-nowrap">
                              <div className="square-72 d-block mr-8 mb-7 mb-sm-0">
                                {loading ? (
                                  <Skeleton height={70} width={70} />
                                ) : (
                                  <img
                                    src={imageNotFound}
                                    alt="Image not found"
                                    width={72}
                                  />
                                )}
                              </div>
                              <div className="w-100 mt-n2">
                                {loading ? (
                                  <div>
                                    <Skeleton height={14} width={200} />
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center justify-content-md-between flex-wrap">
                                    <Link
                                      //  to="/#"
                                      onClick={(e) => e.preventDefault()}
                                      className="font-size-4 text-gray mr-5 font-weight-bold"
                                    >
                                      {talent?.educationDegree}
                                    </Link>
                                  </div>
                                )}
                                {loading ? (
                                  <div>
                                    <Skeleton height={14} width={200} />
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center justify-content-md-between flex-wrap">
                                    <span className="font-size-3 text-gray mr-5">
                                      {`${talent?.educationUniversity}, ${talent?.educationCountry}`}
                                    </span>
                                  </div>
                                )}
                                {loading ? (
                                  <div>
                                    <Skeleton height={14} width={200} />
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center justify-content-md-between flex-wrap">
                                    <span className="font-size-3 text-gray mr-5">
                                      {talent?.educationYear}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </div>

              {/* <div className="col-12 col-xxl-3 col-md-4 offset-xxl-0 offset-lg-4 offset-md-5 order-3 order-xl-2 mt-xxl-0 mt-md-12">
                <div className="pl-lg-5">
                  <h4 className="font-size-6 font-weight-semibold mb-0">
                    Other experts
                  </h4>
                  <ul className="list-unstyled">
                    {experts.map((expert, index) => (
                      <li key={index} className="border-bottom">
                        <button
                          // to="/candidate-profile"
                          // state={{ id: expert.account_id }}
                          onClick={() => window.location.reload()}
                          // onClick={navigate("/candidate-profile", {
                          //   state: {
                          //     id: expert.account_id,
                          //   },
                          //   replace: true
                          // })}
                          className="media align-items-center py-9 border-0 bg-transparent focus-reset"
                        >
                          <div className="mr-7">
                            {loading ? (
                              <Skeleton width={72} height={72} />
                            ) : (
                              <img
                                className="square-72 rounded-3"
                                src={Avatar}
                                alt={`${expert.firstname} ${expert.firstname} Devshop profile`}
                              />
                            )}
                          </div>
                          {loading ? (
                            <Skeleton width={150} height={72} />
                          ) : (
                            <div className="">
                              <h4 className="mb-0 font-size-5 font-weight-semibold">
                                {`${expert.firstname} ${expert.lastname.charAt(0)}`}
                              </h4>
                              <p className="mb-0 font-size-3 heading-default-color">
                                {expert?.title}
                              </p>
                              <span className="font-size-3 text-smoke">
                                <img className="mr-2" src={imgL} alt="" />
                                {`${expert?.city}, ${expert.country}`}
                              </span>
                            </div>
                          )}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default CandidateProfile;
